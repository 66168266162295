import React, {useState, useEffect} from 'react'
import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import Select from 'react-select'
import {Button, ButtonGroup} from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter
} from 'react-icons/fi';


export default function Material() {

    const [filter, setFilter] = useState(false)
    const [filterOption, setFilterOption] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])


    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('lastName')) {
            el = "&lastName=" + formData.get('lastName')
        }
        if (formData.get('id')) {
            el += "&id=" + formData.get('id')
        }

        window.location.replace("/clients?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Clients</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                            </ol>
                        </nav>
                        <div className="d-flex ">

                            <ButtonGroup gap='2'>
                                {/*{*/}
                                {/*    filterOption ? <Button leftIcon={<FiFilter />} colorScheme='red' onClick={() => setFilterOption((e) => !e)} >Close filter</Button>*/}
                                {/*        : <Button leftIcon={<FiFilter />} colorScheme='green' onClick={() => setFilterOption((e) => !e)} > Open filter</Button>*/}
                                {/*}*/}

                                <Link to="/client/create"><Button leftIcon={<FiPlusCircle/>}
                                                                  colorScheme='brand'>New</Button></Link>
                            </ButtonGroup>
                        </div>

                    </div>

                    <div className="card card-body mb-3 mt-4">
                        <form onSubmit={filerArticle}>
                            <div className="row">

                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                           placeholder="Name" name="lastName"/>
                                </div>


                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                           placeholder="Id number" name="id"/>
                                </div>


                                <div className="col">
                                    <Button isLoading={filter} className="w-100" colorScheme='brand'
                                            type="submit"> Apply</Button>
                                </div>

                                <div className="col">
                                    <Button className="w-100" colorScheme='red'
                                            type="button" onClick={() => window.location.replace("/clients")}> Reset
                                        filter</Button>
                                </div>

                            </div>

                        </form>

                    </div>

                    <DataTable column={[
                        {label: "ID Number", field: "id"},
                        {label: "Code promo", field: "codePromo"},
                        {label: "First name", field: "firstName"},
                        {label: "Last name", field: "lastName"},
                        {label: "Credit ($)", field: "credit"},
                        {label: "Email ", field: "email"},
                        {label: "Phone number ", field: "phone"},
                        {label: "City ", field: "city"},
                        {label: "Address ", field: "address"},
                        {label: "Bank name ", field: "bankName"},
                        {label: "Account number ", field: "accountNumber"},
                        {label: "Account name ", field: "accountName"},
                        {label: "Mobile money number ", field: "mobileMoney"},

                    ]}

                               filterOption={filterOption}
                               filterLoader={onChangeFilterLoader}
                               element={"material"}
                               resource={resources.user}


                    />
                </div>
            </div>

        </div>
    )
}
