import Axios from "../Axios";
import axios from "axios"
import config from "../../constants";

const user = {
    read(limit: number, page: number, rule: null) {
        return Axios.get('/user?limit=' + limit + "&page=" + page + "&" + rule,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/user/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object) {
        return Axios.post('/user', data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    createClient(data: object) {
        return axios.post(config.server2+ '/user', data)
    },
    update(id: string, data: object) {
        return Axios.put('/user/' + id, data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/users/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default user;
